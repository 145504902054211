.boton-archivo input:hover{
    cursor: pointer !important;
}

/* .entry-table-container{
    height: 60vh;
    overflow-x: scroll;
    overflow-y: scroll;
} */

.resultados-container{
    width: 100%;
    height: 100%;
}

.card-resultados-body{
    padding: 40px;
}

.waiting-container{
    margin-top: auto;
    margin-bottom: auto;
}

.boton-csv-asos a{
    display: flex;
    justify-content: end;
    align-items: center;
    color: var(--nextui-colors-text);
}