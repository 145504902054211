.page-container{
    margin-left: 275px;
    height: 100%;
    padding: 50px;
    /* overflow-y: scroll; */
    transition: var(--tran-general);
}
.page-container.side-bar-collapsed{
    margin-left: 100px;
    padding: 50px;
}

.titulo-seccion{
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  line-height: 40pt;
}

.resaltar-texto{
  color: var(--nextui-colors-primary);
}

.descripcion-seccion{
    color: var(--nextui-colors-inactivo);
    font-size: 18px;
    font-weight: 500;
}

header{
    margin-bottom: 50px;
}

@media (max-width: 991px) { 
  /* En mobile, se invierte, en  */
  .page-container, .page-container.side-bar-collapsed{
    margin-left: 0px;
    padding: 30px;
    padding-top: 80px;
    height: 100%;
  }
}