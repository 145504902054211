
.menu-usuario-trigger{
    padding: 50px;
}

.nombre-usuario{
    padding-right: 10px;
    font-size: 18px;
}

.dropdown-trigger-button{
    display: flex;
    align-items: center;
    /* align-content: center; */
    cursor: pointer;
}